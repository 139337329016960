<template>
  <!-- 新增新闻管理页面 -->
  <div>
    <!-- 填写表单 -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="120px"
      :rules="rules"
      v-loading="loading"
    >
      <el-form-item label="标题名称" prop="content">
        <el-input
          :disabled="true"
          v-model="ruleForm.title"
          placeholder="请输入标题"
          size="mini"
          style="width: 600px"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input
          :disabled="true"
          placeholder="请输入序号"
          v-model.number="ruleForm.sort"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          size="mini"
          style="width: 150px"
        ></el-input>
      </el-form-item>
      <el-form-item label="图文类型">
        <el-radio-group v-model="ruleForm.typeId">
          <el-radio
            disabled
            v-for="(item, index) in radio"
            :key="index"
            :label="item.id"
            >{{ item.typeName }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="图片">
        <el-upload
          disabled
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :http-request="mainPicUpload"
        >
          <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="链接地址">
        <el-input
          :disabled="true"
          v-model="ruleForm.url"
          size="mini"
          style="width: 600px"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容描述">
        <el-input
          :disabled="true"
          v-model="ruleForm.content"
          size="mini"
          style="width: 600px"
        ></el-input>
      </el-form-item>
      <el-form-item label="文章内容">
        <quill-editor
          disabled
          class="info-editor"
          v-model="ruleForm.contentDescripe"
          ref="QuillEditor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item>
        <div class="btn">
          <el-button type="" @click="getBack">返回</el-button>
          <!-- <el-button type="warning" @click="submitForm('ruleForm')"
            >校验</el-button
          > -->
          <!-- <el-button type="primary" @click="addNews">保存</el-button> -->
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// require styles
// require styles
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
const titleConfig = {
  "ql-bold": "加粗",
  "ql-color": "字体颜色",
  "ql-font": "字体",
  "ql-code": "插入代码",
  "ql-italic": "斜体",
  "ql-link": "添加链接",
  "ql-background": "背景颜色",
  "ql-size": "字体大小",
  "ql-strike": "删除线",
  "ql-script": "上标/下标",
  "ql-underline": "下划线",
  "ql-blockquote": "引用",
  "ql-header": "标题",
  "ql-indent": "缩进",
  "ql-list": "列表",
  "ql-align": "文本对齐",
  "ql-direction": "文本方向",
  "ql-code-block": "代码块",
  "ql-formula": "公式",
  "ql-image": "图片",
  "ql-video": "视频",
  "ql-clean": "清除字体样式",
};

export function addQuillTitle() {
  const oToolBar = document.querySelector(".ql-toolbar"),
    aButton = oToolBar.querySelectorAll("button"),
    aSelect = oToolBar.querySelectorAll("select"),
    aSpan = oToolBar.querySelectorAll("span");
  aButton.forEach((item) => {
    if (item.className === "ql-script") {
      item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
    } else if (item.className === "ql-indent") {
      item.value === "+1"
        ? (item.title = "向右缩进")
        : (item.title = "向左缩进");
    } else if (item.className === "ql-list") {
      item.value === "ordered"
        ? (item.title = "有序列表")
        : (item.title = "无序列表");
    } else if (item.className === "ql-header") {
      item.value === "1" ? (item.title = "标题H1") : (item.title = "标题H2");
    } else {
      item.title = titleConfig[item.classList[0]];
    }
  });
  aSelect.forEach((item) => {
    if (item.className != "ql-color" && item.className != "ql-background") {
      item.parentNode.title = titleConfig[item.classList[0]];
    }
  });
  aSpan.forEach((item) => {
    if (item.classList[0] === "ql-color") {
      item.title = titleConfig[item.classList[0]];
    } else if (item.classList[0] === "ql-background") {
      item.title = titleConfig[item.classList[0]];
    }
  });
}
// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image", "video"],
  ["clean"], // remove formatting button
];
import { uploadImg } from "@/utils/index";
import loginVue from "../login.vue";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      tableData: [],
      loading: false,
      ruleForm: {
        content: "",
        contentDescripe: "",
        image: "",
        sort: null,
        status: "",
        title: "",
        typeId: null,
        url: "",
      },
      radio: "",
      imageUrl: "",

      //   验证规则
      rules: {
        categoryName: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        Name: [{ required: true, message: "请输入序号", trigger: "blur" }],
      },
      editorOption: {
        placeholder: "请输入编辑内容",
        theme: "snow", //主题风格
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector("#quill-upload input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      }, // 富文本编辑器配置
      content: "", //富文本内容
    };
  },
  mounted() {
    addQuillTitle();
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  created() {
    // 获取单选框
    this.axios.get("/admin/consultType/findAll", {}).then((res) => {
      if (res.data.code == 200) {
        this.radio = res.data.data.records;
        console.log(this.radio, "+++++++");
      }
    });
    this.onNesClassification();
  },
  methods: {
    // 获取详情
    onNesClassification() {
      let newid = this.$route.query.id;
      this.axios
        .get("/admin/consult/findById", {
          id: newid,
        })
        .then((res) => {
          this.ruleForm = res.data.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addNews() {
      this.axios
        .post("/admin/consult/addConsult", this.ruleForm)
        .then((res) => {
          if (res.data.code == 200) {
            this.open2();
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            setTimeout(() => {
              this.getBack();
            }, 1000);
          } else {
            this.$message({
              message: "保存失败",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    onEditorBlur(quill) {
      // console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      // console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      // console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      // console.log("editor change!", quill, html, text);
      this.content = html;
    },
    //   保存成功
    open2() {
      this.$message({
        message: "保存成功",
        type: "success",
      });
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },
    //保存
    Save(formName) {
      console.log(this.ruleForm);
      this.submitForm(formName);
      this.open2();
      this.getBack();
    },
    // 校验
    submitForm(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message({
            message: "校验通过",
            type: "success",
          });
        } else {
          this.$message({
            message: "请填写完整数据",
            type: "error",
          });
          return false;
        }
      });
    },
    mainPicUpload(options) {
      this.loading = true;
      function dataUrl(data) {
        let arr = data.split("&");
        let list = {};
        arr.forEach((item) => {
          let itm = item.split("=");
          list[itm[0]] = decodeURIComponent(itm[1]);
        });
        return list;
      }
      var { file } = options;
      let data;
      this.axios.get("/getOSSKey", {}).then((res) => {
        data = res.data.data;
        var ossData = new FormData();
        ossData.append("name", file.name);
        let filename = `${Date.parse(new Date())}` + file.name; //图片名称
        let keyValue = data.dir + filename;
        //请求数据
        ossData.append("key", keyValue);
        ossData.append("callback", data.callback);
        ossData.append("policy", data.policy);
        ossData.append("OSSAccessKeyId", data.accessid);
        ossData.append("success_action_status", 200);
        ossData.append("signature", data.signature);
        ossData.append("file", file);
        this.axios.post(data.host, ossData).then((res) => {
          this.imageUrl = dataUrl(res.data.Status);
          this.imageUrl = this.imageUrl.filename;
          this.ruleForm.image = this.imageUrl;
          this.loading = false;
        });
      });
    },

    handleAvatarSuccess(res, file) {
      console.log(res, file);
      // this.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.imageUrl);
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.info-editor {
  height: 20vw;
  width: 60vw;
}
.btn {
  margin-top: 100px;
}
</style>
